.section-about {
  position: relative;
  background-color: $color-black;
  background-image: url(../assets/images/bg-1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: $color-white;

  &:after {
    background-color: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: '';
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.about {
  &-text {
    max-width: 1000px;
    padding: 120px 100px 40px 0;
    margin: 0 auto;

    p {
      font-size: 18px;
    }

    h2 {
      font-size: 36px;
      line-height: 1.2em;
      margin-bottom: .5em;
    }
  }
  &-video {
    padding-bottom: 120px;
    max-width: 1000px;
    margin: 0 auto;
  }
  &-side {
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 24px;
    font-weight: 700;
    transform: rotate(270deg) translateY(-50%);
  }
}

@media screen and (max-width: $screen-tab) {
  .about {
    &-text {
      padding: 120px 0 100px 120px;
      p {
        font-size: 16px;
      }

      h2 {
        font-size: 28px;
      }
    }
  }
}

@media screen and (max-width: $screen-tab) {
  .section-about {
    padding: 40px 0;
  }
  .about {
    &-side {
      position: relative;
      transform: none;
      margin-bottom: 20px;
      text-align: center;
    }
    &-text {
      padding: 0 0 40px;
      
      h2 {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    &-video {
      padding-bottom: 0;
    }
  }
}
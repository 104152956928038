.section-livestream,
.section-live {
  position: relative;
  background: linear-gradient(90deg,#000050,#6b15d5,#328fff);
  color: $color-white;

  .container {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px;

    @media screen and (max-width: $screen-mob) {
      padding: 40px 20px;
    }
  }
}

.section-livestream {
  .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 400px));
    justify-content: center;
    text-align: center;
    gap: 40px;

    @media screen and (max-width: $screen-mob) {
      padding: 40px 20px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 20px;
    }
  }
}

.livestream {
  &-link {
    height: 400px;
    color: $color-white;
    // background-color: #000;
    box-shadow: 0 0 6px 2px rgba(38,38,38,.4);
    transition: all .25s ease-in;
    padding: 40px;

    @media screen and (max-width: $screen-mob) {
      min-height: 200px;
      height: auto;
    }

    &:hover {
      box-shadow: 0 0 10px 6px rgba(38,38,38,.4);
    }

    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    
    &:nth-of-type(1n) {
      background: linear-gradient(225deg, rgba(168,199,132,1) 0%, rgba(52,92,162,1) 41%, rgba(5,1,86,1) 100%);
    }

    &:nth-of-type(2n) {
      background: linear-gradient(225deg, rgba(5,1,86,1) 0%, rgba(52,92,162,1) 41%,rgba(168,199,132,1) 100%);
    }

    span {
      font-size: 40px;
      font-weight: 700;

      @media screen and (max-width: $screen-mob) {
        font-size: 28px;
      }
    }
  }

  &-text {
    max-width: 1000px;
    padding: 120px 100px 40px 0;
    margin: 0 auto;
  }
}

.section-live {
  h1 {
    font-size: 60px;
    line-height: 1em;
    margin-bottom: .2em;
  }

  p {
    font-size: 20px;
    line-height: 1em;
    margin-bottom: 1.2em;
  }

  @media screen and (max-width: $screen-mob) {
    h1 {
      font-size: 42px;
    }

    p {
      font-size: 18px;
    }
  }
}
.section-photogallery {
  .container {

    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px;

    @media screen and (max-width: $screen-mob) {
      padding: 40px 20px;
    }
  }

    img {
      object-fit: cover;
      cursor: pointer;
    }
}
.section-maps {
  padding: 120px 0;
  background-image: linear-gradient(to right,#000050,#6B15D5,#328FFF);
  color: $color-white;

  h2 {
    font-size: 60px;
    text-align: center;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 36px;
    text-align: left;

    a {
      color: $color-white;
      text-decoration: none;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: $screen-mob) {
  .section-maps {
    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 18px;
    }
  }
}


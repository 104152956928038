/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html, body {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

#ot-sdk-btn-floating .ot-floating-button__back svg,
#ot-sdk-btn-floating .ot-floating-button__front svg {
  display: inline-block;
}

input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}
a {
  display: inline-block;
}

@import 'aos.scss';

// fonts 

$font-path: "../assets/font";


@font-face{
  font-family: "WPP-Font";
  src: url('#{$font-path}/WPP-Light.woff');
  font-weight: 300;
}

@font-face{
  font-family: "WPP-Font";
  src: url('#{$font-path}/WPP-Regular.woff');
  font-weight: 500;
}

@font-face{
  font-family: "WPP-Font";
  src: url('#{$font-path}/WPP-Bold.woff');
  font-weight: 600;
}

@font-face{
  font-family: "WPP-Font";
  src: url('#{$font-path}/WPP-Black.woff');
  font-weight: 900;
}


// colors

$color-blue: #000050;
$color-black: #262626;
$color-white: #f2f2f2;
$color-cyan: #12a8b5;
$color-gray: #aaaaaa;
$color-lightblue: #0090ff;

// screens

$screen-tab: 1100px;
$screen-mob: 640px;

// nav

html {
  font-size: 16px;
  font-family: "WPP-Font";
}

body .btn {
  &-nav {

    a {
      color: $color-white;
      background-color: $color-blue;
      padding: 10px 20px;
      border: 2px solid $color-blue;
      border-radius: .25rem;
      transition: all .15s ease-in;
    }

    &:hover {
      a {
        border: 2px solid $color-blue;
        color: $color-blue;
        background-color: $color-white;
      }
    }
  }

  &-hero {
    text-align: center;
    a,span {

      position: relative;
      padding: 20px 40px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      font-size: 30px;
      margin: 1em 0.8em;
      font-weight: 700;
      text-decoration: none;
      color: $color-white;
      text-transform: uppercase;

      &:after,
      &:before {
        color: $color-lightblue;
        content: '';
        display: block;
        position: absolute;
        width: 20%;
        height: 40%;
        border: 2px solid;
        transition: all 0.6s ease;
        border-radius: 2px;
      }

      &:after {
        bottom: 0;
        right: 0;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom-color: $color-lightblue;
        border-right-color: $color-lightblue;
      }

      &:before {
        top: 0;
        left: 0;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-top-color: $color-lightblue;
        border-left-color: $color-lightblue;
      }

      &:hover:after,
      &:hover:before {
        width: 100%;
        height: 100%;
      }

      // display: inline-block;
      // font-size: 30px;
      // color: $color-white;
      // text-decoration: none;
      // padding: 10px 30px;
      // background:
      //   linear-gradient(to right, $color-lightblue 2px, transparent 2px) 0 0,
      //   linear-gradient(to left, $color-lightblue 2px, transparent 2px) 100% 100%,
      //   linear-gradient(to bottom, $color-lightblue 2px, transparent 2px) 0 0,
      //   linear-gradient(to top, $color-lightblue 2px, transparent 2px) 100% 100%;

      // background-repeat: no-repeat;
      // background-size: 25px 25px;
    }

    span {
      display: inline-block;
      margin-top: 20px !important;
    }
  }

  @media screen and (max-width: $screen-tab) {
    &-hero {
      a {
        font-size: 24px;
        padding: 14px 20px;
      }
    }
  }
}

button.btn-fav {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;

  span {
    content: "";
    margin-right: 4px;
    width: 20px;
    height: 20px;
    display: inline-block;
    background-image: url(../assets/images/icons-sprite.svg);
    background-position: -185px 154px;
    background-size: 229px 209px;
  }

  &.btn-active {
    span {
      background-position: -185px 128px;
    }
  }
}

button.btn-program,
a.btn-program {
  position: relative;
  padding: 20px 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  margin: 1em 0.8em;
  font-weight: 700;
  text-decoration: none;
  color: $color-white;
  text-transform: uppercase;

  &:after,
  &:before {
    color: $color-lightblue;
    content: '';
    display: block;
    position: absolute;
    width: 20%;
    height: 40%;
    border: 2px solid;
    transition: all 0.6s ease;
    border-radius: 2px;
  }

  &:after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $color-lightblue;
    border-right-color: $color-lightblue;
  }

  &:before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: $color-lightblue;
    border-left-color: $color-lightblue;
  }

  &:hover:after,
  &:hover:before {
    width: 100%;
    height: 100%;
  }
}

.container {
  max-width: 1640px;
  padding: 0 1.25rem;
  margin: 0 auto;
}

.scroll-top-button {
  position: fixed;
  bottom: 10px;
  right: 30px;

  animation: opacityUp .3s ease-in ;

  background-color: rgba($color-white, .8);
  border-radius: 10px;
  box-shadow: 0 0 10px 4px rgba($color-black, .1);

  background-image: url(../assets/images/icons-sprite.svg);
  background-position: -320px 210px;
  background-size: 600px 600px;

  z-index: 1000;

  cursor: pointer;

  width: 60px;
  height: 60px;

  @media screen and (max-width: $screen-tab) {
    bottom: 10px;
    right: 10px;
  }
}


@keyframes opacityUp {
  from {opacity: 0;}
  to {opacity: 1}
}

@import "header.scss";
@import "countdown.scss";
@import "hero.scss";
@import "about.scss";
@import "program.scss";
@import "stands.scss";
@import "register.scss";
@import "contact.scss";
@import "partners.scss";
@import "footer.scss";
@import "speakers.scss";
@import "maps.scss";
@import "error.scss";
@import "app.scss";
@import "livestream.scss";
@import "videos.scss";
@import "gallery.scss";

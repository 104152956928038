footer {
  background-color: $color-white;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 90px 90px 75px;
    max-width: 2400px;

    ul {
      padding: 0;
      list-style-type: none;
      li {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 15px;
        font-weight: 300;
        color: $color-black;
        a {
          color: $color-black;
          text-decoration: none;
          font-size: 14px;
          font-weight: 300;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.footer {
  &-links {
    ul {
      padding: 0;
      max-width: 840px;
      list-style-type: none;
      display: grid;
      grid-template-columns: repeat(2, 400px);
      flex-wrap: 40px;  
    }
  }
}

@media screen and (max-width: $screen-tab) {
  footer {
    .container {
      padding: 40px 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .footer {
    &-links {
      width: 100%;
      margin-bottom: 30px;
      ul {
        max-width: 100%;
        justify-content: start;
        grid-template-columns: repeat(2, 150px);
      }
    }

    &-side {
      ul {
        display: flex;
        li {
          margin: 0 20px;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-mob) {
  .footer {
    &-links {
      margin-bottom: 0;
      ul {
        grid-template-columns: repeat(1, 100%);
        text-align: center;
      }
    }

    &-side {
      ul {
        display: flex;
        flex-direction: column;
        text-align: center;
        li {
          margin: 0 20px;
          margin-bottom: 10px !important;

          &:last-of-type {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}
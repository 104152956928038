.section-contact {
  background-color: #fff;
  padding: 0;
  .container {
    max-width: 100%;
    padding: 0;
    h2 {
      font-size: 42px;
    }

    h3 {
      font-size: 28px;
      margin-top: 20px;
    }

    p {
      font-size: 18px;

      &:first-of-type {
        margin-bottom: 20px;
      }
    }

    a {
      display: inline-block;
      color: $color-black;
      font-size: 18px;
      text-decoration: underline;
    }
  }
}

.contact {
  &-info {
    max-width: 2400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &-image {
    padding: 40px;
  }

  &-map {
    background-color: $color-white;
  }

  &-col {
    &:first-of-type {
      padding: 40px;
    }
  }
}

@media screen and (max-width: $screen-tab) {
  .contact {
    &-info {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &-image {
      padding: 0;
    }

    &-col {

      &:first-of-type {
        padding: 40px 20px;
      }

      h2 {
        text-align: center;
      }
    }
  }
}
.section {
  &-hero {
    color: $color-white;
    // padding: 240px 0;
    padding: 120px 0 0px;
    position: relative;
    // background: rgb(54,3,136);
    // background: linear-gradient(45deg, rgba(54,3,136,1) 0%, rgba(101,8,189,1) 25%, rgba(116,11,204,1) 50%, rgba(96,40,223,1) 75%, rgba(54,89,235,1) 100%);  
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,.65);
      content: '';
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 2;
    }
  }
}

.hero {
  &-logo {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    width: 400px;
    height: 200px;
    background-image: url(../assets/images/WPP-logo-white.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-text {
    font-size: 180px;
    line-height: 1em;
    font-weight: 900;
    display: inline-block;
    position: relative;
    top: 5px;
    margin-left: 60px;
  }

  &-bottom {
    font-size: 82px;
    line-height: 1em;
    display: block;
    text-align: center;
    margin-top: 40px;
  }

  &-date {
    font-size: 52px;
    line-height: 1em;
    margin-top: 20px;
    text-align: center;
    display: block;
  }
}

@media screen and (max-width: $screen-tab) {
  .section-hero {
    padding: 80px 0 40px;
  }

  .hero {
    &-logo {
      width: 270px;
      height: 80px;
    }

    &-text {
      font-size: 120px;
      margin-left: 30px;
    }

    &-bottom {
      font-size: 52px;
      margin-top: 20px;
    }

    &-date {
      font-size: 32px;
    }
  }
}

@media screen and (max-width: $screen-mob) {
  .hero {
    &-top {
      flex-direction: column;
    }
    &-logo {
      width: 260px;
      margin-bottom: 20px;
    }

    &-text {
      margin: 0 0 40px;
      font-size: 100px;
    }

    &-bottom {
      font-size: 40px;
      max-width: 260px;
      margin: 0 auto 20px;
    }

    &-date {
      font-size: 26px;
      margin: 0 auto 30px;
    }
  }
}

.section-hero .video-react {
  padding: 0 !important;
}

.section-hero .video-react-control-bar,
.section-hero .video-react-button {
  display: none !important;
  visibility: none;
}

.section-hero .video-react video,
.hero-image,
#bgvideo {
  position: fixed !important;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: calc( 100vh - 70px );
  width: auto;
  height: 100%;
  z-index: -100;
  object-fit: cover;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  // background: url(placeholder.jpg) no-repeat;
  background-size: cover !important;
}

.hero-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}


.section-videos {
  position: relative;
  background: linear-gradient(90deg,#000050,#6b15d5,#328fff);
  color: $color-white;

  .container {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px;

    @media screen and (max-width: $screen-mob) {
      padding: 40px 20px;
    }
  }

  h1 {
    font-size: 60px;
    text-align: center;
    margin-bottom: 40px;

    @media screen and (max-width: $screen-mob) {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 34px;
    text-align: center;
    margin-bottom: 25px;

    @media screen and (max-width: $screen-mob) {
      font-size: 18px;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
    list-style-type: none;
    padding: 0;

    .grid-item {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      height: 210px;
      box-sizing: border-box;
      border: 1px solid rgba(38, 38, 38, 0.3);
      box-shadow: 0 0 6px 2px rgba(38, 38, 38, .4);
      padding: 40px;
      background-color: rgba(38, 38, 38, .4);
    }
  }

  .grid-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
    list-style-type: none;
    padding: 0;

    a {
      color: inherit;
      text-decoration: none;
      height: 100%;

      p {
        text-decoration: none;
      }
    }

    li {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      //height: 420px;
      box-sizing: border-box;
      border: 1px solid rgba(38, 38, 38, 0.3);
      box-shadow: 0 0 6px 2px rgba(38, 38, 38, .4);
      padding: 40px;
      background-color: rgba(38, 38, 38, .4);

      h3 {
        display: inline-block;
        width: fit-content;
        position: relative;
        font-size: 34px;
        left: 0;
        top: 0;
        // font-size: 30px;
        margin-bottom: 10px;
      }

    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
  }

  .btn-hero {
    text-align: left;
    span {
      font-size: 20px;
      padding: 8px 16px;
      margin-top: 0 !important;
      margin-left: 0;
      margin-right: 40px;
    }
  }

  &.section-program {
    background: $color-white;
    color: $color-black;

    h1, h2 {
      text-align: left;
    }

    .form-input-text {
      input {
        color: $color-black;
        border-bottom: 1px solid rgba($color-black, .3);
      }
    }

    .btn-hero {
      span {
        color: $color-black;
      }
    }
  }
}

.field--name-field-media-oembed-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.form-input-text {
  color: $color-white;
  margin-bottom: 2em;
  label {
    display: block;
    font-size: 20px;
    line-height: 0em;
    margin: 0;
  }

  .input-error {
    color: #cf1818;
    padding-top: 10px;
  }

  input {
    display: block;
    width: 100%;
    max-width: 400px;
    background: none;
    border: none;
    border-bottom: 1px solid $color-white;
    font-size: 20px;
    padding: 0.5em 0;
    color: $color-white;
    letter-spacing: 4px;

    &:focus {
      outline: none;
    }
  }

  input::placeholder {
    color: $color-white;
  }
}


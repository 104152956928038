$gradient_brand: linear-gradient(45deg, rgba(72,10,32,1) 0%, rgba(160,0,26,1) 50%, rgba(255,92,0,1) 100%);
$color_brand: #2BFFC1;
$gradient_consumer: linear-gradient(45deg, rgba(255,92,0,1) 0%, rgba(255,239,44,1) 50%, rgba(255,199,210,1) 100%);
$color_consumer: #6B15D5;
$gradient_future: linear-gradient(45deg, rgba(0,0,80,1) 0%, rgba(107,21,213,1) 50%, rgba(255,92,0,1) 100%);
$color_future: #FFEF2C;
$gradient_data: linear-gradient(45deg, rgba(0,0,80,1) 0%, rgba(50,143,255,1) 66%, rgba(147,223,227,1) 85%);
$color_data: #FFEF2C; 
$gradient_media: linear-gradient(45deg, rgba(50,143,255,1) 0%, rgba(0,194,43,1) 50%, rgba(43,255,193,1) 85%);
$color_media: #31117C;
$gradient_person: linear-gradient(45deg, rgba(160,0,26,1) 0%, rgba(255,92,0,1) 50%, rgba(255,239,44,1) 85%);
$color_person: #31117C;
$gradient_creativity: linear-gradient(45deg, rgba(50,143,255,1) 0%, rgba(43,255,193,1) 50%, rgba(255,199,45,1) 85%);
$color_creativity: #6B15D5;
$gradient_content: linear-gradient(45deg, rgba(107,21,213,1) 0%, rgba(225,33,162,1) 50%, rgba(255,199,45,1) 100%);
$color_content: #2BFFC1;
$gradient_sustain: linear-gradient(45deg, rgba(0,0,80,1) 0%, rgba(50,143,255,1) 45%, rgba(0,194,43,1) 75%, rgba(255,239,44,1) 100%);
$color_sustain: #FFC7D2;


@use "sass:map";

$gradients: (brand: $gradient_brand, consumer: $gradient_consumer, future: $gradient_future, data: $gradient_data, media: $gradient_media, person: $gradient_person, creativity: $gradient_creativity, content: $gradient_content, sustain: $gradient_sustain);
$colors: (brand: $color_brand, consumer: $color_consumer, future: $color_future, data: $color_data, media: $color_media, person: $color_person, creativity: $color_creativity, content: $color_content, sustain: $color_sustain);


.section {
  &-speakers {
    padding: 120px 0;
    // background-image: linear-gradient(to right,#000050,#6B15D5,#328FFF);
    background-color: #fff;
   h2, p {
      text-align: center;
   } 

   h2 {
    text-align: center;
    font-size: 42px;
    margin-bottom: 20px;
    color: $color_black;
   }

   .container {
    max-width: 1400px;
    .speakers-wrapper > p {
      font-size: 28px;
      text-align: center;
      color: $color_black;
      margin-bottom: 80px;
    }
   }
  }
}

.speakers {

  &-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
    padding: 0;

    @media screen and (min-width: $screen-tab) and (max-width: 1400px) {
      gap: 20px;
    }

    li {
      
      list-style-type: none;
      // height: 600px;
      position: relative;
      overflow: hidden;

      // box-shadow: 0 0 30px 2px rgba(38, 38, 38, .2);
    }

    .speaker-image {
      height: 413px;
      padding: 30px;
      margin-bottom: 30px;

      @media screen and (min-width: $screen-tab) and (max-width: 1400px) {
        height: calc( (100vw - 140px) / 3);
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      &.speaker-silhoutte {
        // filter: drop-shadow(10px -5px 10px rgba(0,0,0, 0.5));
        // box-shadow: none;
        position: relative;
        bottom: -30px;
      }
    }

    h3 {
      z-index: 2;
      font-size: 44px;
      line-height: 1;
      font-weight: 600;
      margin-bottom: 10px;
    }

    p {
      z-index: 2;
      font-size: 20px;
      max-width: 90%;
      text-align: left;
      font-weight: 500;
      color: $color_gray;
    }
  }
}


@media screen and (max-width: $screen-tab) {
  .section {
    &-speakers {
      padding: 40px 0;
      .container {
        .speakers-wrapper > p {
          font-size: 22px;
          margin-bottom: 40px;
        }
      }
    }
  }

  .speakers {
    
    &-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 20px;
    
      .speaker-image {
        padding: 20px;
        margin-bottom: 20px;
        height: calc( (100vw - 60px) / 2);
      }

      h3 {
        font-size: 32px;
      }

      p {
        font-size: 16px;
        line-height: 1.2em;
      }
    }
  }
}


@media screen and (max-width: $screen-mob) {
  .speakers {
    
    &-list {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 20px;
    
      .speaker-image {
        padding: 14px;
        margin-bottom: 10px;
        height: calc( (100vw - 80px));
      }

      h3 {
        font-size: 28px;
      }

      p {
        max-width: 100%;
      }
    }
  }
}



@function get_color( $index ) {
  @return map-get($colors, $index);
}

@function get_bg( $index ) {
  @return map-get($gradients, $index);
}

@each $kvp in $gradients { // kvp stands for 'key-value pair'
  .speaker-bg--#{nth($kvp, 1)} {
    .speaker-image {
      // background-color: rgba( get_color( nth($kvp, 1)), .15) !important;
      background: nth($kvp, 2);  
      // color: get_color( nth($kvp, 1));
    }
  }
}

// stands gradients
li[class^="stands-bg"] {
  border: none;
}
@each $kvp in $gradients { // kvp stands for 'key-value pair'
  .stands-bg--#{nth($kvp, 1)} {
    background: get_bg( nth($kvp, 1));
    color: get_color( nth($kvp, 1));
  }
}
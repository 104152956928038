.section-program {
  background-color: $color-white;
  color: $color-black;
  padding: 80px 0;
  .container {
    max-width: 1240px;
  }

  h2 {
    text-align: center;
    font-size: 42px;
    margin-bottom: 20px;
  }

  .container {
    > .program-text {
      p {
        text-align: center;
        font-size: 28px;
        max-width: 1000px;
        margin: 0 auto 40px;
      }
    }
  }
}

// .has-detail {
//   cursor: pointer;
// }

.program {
  &-controls {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    li {
      margin: 0 40px;

      button, a.btn-program {
        color: $color-black;
      }
    }
  }

  &-wrapper {

  }

  &-list {
    margin-bottom: 80px;
    
    &:after {
      content: '';
      display: block;
      width: 80%;
      height: 5px;
      margin: 0 auto;
      background: rgb(54,3,136);
      background: linear-gradient(45deg, rgba(54,3,136,1) 0%, rgba(101,8,189,1) 25%, rgba(116,11,204,1) 50%, rgba(96,40,223,1) 75%, rgba(54,89,235,1) 100%);  
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }

  &-title {
    font-size: 30px;
    margin-bottom: 1rem;
  }

  &-tags {
    padding: 0;
    list-style-type: none;
  }

  &-time {
    font-size: 50px;
    line-height: 1;
  }

  &-place {
    font-size: 28px;
    line-height: 1;
  }

  &-more {
    .btn-hero {
      a {
        color: $color-black;
        font-size: 18px;
        max-width: 300px;
      }
    }
  }

  &-col {
    min-width: 0;
    min-height: 0;
    p {
      font-size: 18px;
    }

    h3 {
      text-align: left;
      font-size: 34px;
      line-height: 1.2;
      margin: 0 0 10px;
    }

    a {
      color: $color-black;
      text-decoration: none;
      font-size: 18px;
      position: relative;
      display: block;
      font-weight: 300;

      &:before {
        content: "";
        margin-right: 4px;
        width: 20px;
        height: 20px;
        display: inline-block;
        background-image: url(../assets/images/icons-sprite.svg);
        background-position: -129px 49px;
        background-size: 226px 212px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-items {
    padding: 0;
    > li {
      display: grid;
      grid-template-columns: minmax(0, 30%) minmax(0, 40%) minmax(0, 30%);
      gap: 20px;
      margin-bottom: 60px;
      
      .program-detail-button {
        grid-column-start: 3;

        font-size: 18px;
        display: flex;
        align-items: center;
        width: fit-content;
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
        align-self: flex-start;

        &:hover {
          text-decoration: underline;
        }

        &.active {
          &:before {
            transform: rotate(45deg);
          }
        }

        &:before {
          content: "";
          width: 20px;
          margin-right: 4px;
          height: 20px;
          display: inline-block;
          background-image: url(../assets/images/icons-sprite.svg);
          background-position: -406px 61px;
          background-size: 290px 290px;
          transition: all .2s ease-in;
        }
      }
      

      .program-detail {
        grid-column: span 2;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows .3s ease-in;

        p {
          font-size: 18px;

          a {
            color: inherit;
          }
        }

        .program-detail-inner {
          overflow: hidden;
        }

        &.active {
          grid-template-rows: 1fr;
        }
      }
    }
  }
}

p.program-text-name {
  font-size: 26px;
}

@media screen and (max-width: $screen-tab) {
  .section-program {
    padding: 40px 0;
    .container { 
    > p {
        font-size: 22px;
        margin: 0 auto 40px;
      }
    }
  }

  .program {
    &-controls {
      li {
        margin: 0 30px;

        button.btn-program,
        a.btn-program {
          padding: 14px 20px;
          margin: 0;
        }
      }
    }

    &-title {
      font-size: 26px;
    }

    &-col {
      &:nth-of-type(3) {
        grid-column-start: 2;
      }

      h3 {
        font-size: 26px;
      }
    }

    &-items {
      > li {
        gap: 14px;
        grid-template-columns: minmax(0, 240px) minmax(0, 100%);

        .program-detail-button {
          margin-top: 20px;
          order: 4;
          grid-column-start: 2;
        }

        .program-detail {
          order: 5;
          grid-column-start: 2;
        }
      }
    }

    &-time {
      font-size: 36px;
      font-weight: 600;
    }

    &-place {
      font-size: 24px;
    }

  }
}

@media screen and (max-width: $screen-mob) {

  .program {
    &-controls {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      li {
        margin: 0 0 20px;
      }
    }

    &-col {
      h3 {
        margin: 0;
      }
      &:nth-of-type(1) {
        order: 2;
      }
      &:nth-of-type(2) {
        order: 1;
      }
      &:nth-of-type(3) {
        order: 3;
        grid-column-start: 1;
      }
    }

    &-items {
      > li {
        grid-template-columns: minmax(0, 100%);
        gap: 20px;

        border-bottom: 1px solid rgba($color-black, .2);
        padding-bottom: 40px;
        margin-bottom: 40px;

        &:last-of-type {
          border-bottom: none;
          padding-bottom: 0;
        }

        .program-detail {
          grid-column: 1;
          order: 5;
        }

        .program-detail-button {
          grid-column-start: 1;
          order: 4;
        }
      }
    }

    &-list {
      margin-bottom: 60px;
    }

    &-time {
      font-size: 36px;
      font-weight: 600;
    }

    &-place {
      font-size: 24px;
    }

  }
}


// videos

.program-items.program-items-videos {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  > li {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      transition: box-shadow 300ms ease-in-out !important;
      padding: 10px;
  
      &:hover {
        box-shadow: 0 0 10px 5px rgba(0,0,0, 0.1);
      }
    }

    .program-col {
      order: initial;
    }

    .program-col-info {
      margin-top: auto;
    }

    .program-text {
      > * {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.error {
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: $color-white;

  background-image: linear-gradient(to right,#000050,#6B15D5,#328FFF);

  h3 {
    font-size: 10rem;
    line-height: 1em;
  }

  p {
    font-size: 4rem;
    line-height: 1em;

    margin-bottom: 1em;
  }
}
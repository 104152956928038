.section-partners {
  background-color: $color-white;
  padding: 80px 0;
  .container {
    
  }

  h2 {
    font-size: 42px;
    text-align: center;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 40px;
  }
}

.partners {
  &-list {
      max-width: 1120px;
      padding: 0;
      margin: 0 auto 100px;
      list-style-type: none;
      display: grid;
      gap: 80px;
      grid-template-columns: repeat(4, 220px);
      justify-content: center;

      &:nth-of-type(1) {
        grid-template-columns: repeat(1, minmax(0, 300px));
      }

      &:nth-of-type(3),
      &:nth-of-type(4) {
        margin-bottom: 40px;
        grid-template-columns: repeat(1, minmax(0, 400px));
      }

      &:nth-of-type(4) {
        max-width: 200px;
        img {
          max-width: 200px;
        }
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }
}

@media screen and (max-width: $screen-tab) {
  .section-partners {
    padding: 40px 0;

    h3 {
      font-size: 24px;
    }
  }

  .partners {
    &-list {
        gap: 60px;
        justify-content: center;
        grid-template-columns: repeat(3, minmax(0, 220px));
    }
  }
}

@media screen and (max-width: $screen-mob) {
  .partners {
    &-list {
        gap: 40px;
        grid-template-columns: repeat(2, minmax(0, 220px));
    }
  }
}
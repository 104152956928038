.section-stand,
.section-stands {
  padding: 120px 0;
  background-image: linear-gradient(to right,#000050,#6B15D5,#328FFF);
  color: $color-white;
}

.stands {
  &-wrapper {
    h2 {
      font-size: 42px;
      text-align: center;
    }

    p {
      font-size: 28px;
      text-align: center;
      margin-bottom: 60px;
    }
  }

  &-more {
    .btn-hero a,
    .btn-hero span {
      margin-top: 120px;
      color: $color-white;
      font-size: 18px;
      max-width: 300px;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
    list-style-type: none;
    padding: 0;

    a {
      color: inherit;
      text-decoration: none;
      height: 100%;
      
      p {
        text-decoration: none;
      }
    }

    li {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      height: 420px;
      box-sizing: border-box;
      border: 1px solid rgba(38, 38, 38, 0.3);
      box-shadow: 0 0 6px 2px rgba(38, 38, 38, .4);
      padding: 40px;
      background-color: rgba(38, 38, 38, .4);

      h3 {
        display: inline-block;
        width: fit-content;
        position: relative;
        font-size: 34px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .3s ease-in;
      }

      .stands-text {
        opacity: 0;
        transition: all .3s ease-in;
        font-size: 18px;
      }

      .btn-hero {
        opacity: 0;
        transition: all .3s ease-in;
        margin-top: auto;
        text-align: left;
        a,span {
          font-size: 16px;
          margin: 0;
        }
      }

      

      &:hover {
        h3 {
          transform: translate(0, 0);
          left: 0;
          top: 0;
          // font-size: 30px;
          margin-bottom: 10px;
        }
        .stands-text {
          opacity: 1;
        }
        .btn-hero {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .stands {
    &-list {
      justify-content: center;
      grid-template-columns: repeat(2, 500px);
    }
  }
}

@media screen and (max-width: $screen-tab) {
  .section-stands {
    padding: 40px 0;
  }

  .stands {
    &-wrapper {  
      p {
        font-size: 22px;
        margin-bottom: 40px;
      }
    }

    &-more {
      .btn-hero a,
      .btn-hero span {
        margin-top: 60px;
        font-size: 18px;
      }
    }

    &-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 20px;
        
      li {
        height: 100%;
        padding: 30px;
  
        h3 {
          font-size: 28px;
          transform: translate(0, 0);
          left: 0;
          top: 0;
          margin-bottom: 10px;
        }
  
        p,
        .stands-text {
          opacity: 1;
          font-size: 18px;
          margin-bottom: 40px;
        }

        a {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
  
        .btn-hero {

          opacity: 1;
          transition: all .3s ease-in;
          margin-top: auto;
          text-align: left;
          a, span {
            font-size: 16px;
            margin: 0;
          }
        } 
      }
    }
  }
}


@media screen and (max-width: $screen-mob) {
  .stands {

    &-list {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 20px;
        
      li {
        padding: 20px;
  
        h3 {
          font-size: 24px;
          line-height: 1.2;
        }
    
        .btn-hero {
          a,span {
            font-size: 14px;
          }
        } 
      }
    }
  }
}

.section-stand {
  position: relative;

  &.has-banner {

    + .section-partners {
      padding-top: 140px;
    }
  }

  h1 {
    font-size: 60px;
    line-height: 1.4em;

    @media screen and (max-width: $screen-mob) { 
      font-size: 32px;
    }
  }

  p {
    max-width: 760px;
    font-size: 20px;
    margin-bottom: 40px;

    @media screen and (max-width: $screen-mob) { 
      font-size: 18px;
    }
  }

  .media--type-remote-video {
      iframe {
          max-width: 800px;
          width: 100%;
          aspect-ratio: 16/9;
          height: auto;
          margin-bottom: 20px;
          margin-top: 20px;
      }
  }

  .btn-hero {
    text-align: left;

    a, span {
      font-size: 20px;
    }

    @media screen and (max-width: $screen-mob) { 
      // text-align: center;
      a,span {
        font-size: 16px;
      }
    }
  }
}

.stand {
  &-banner {
    background: rgb(170,218,197);
    background: linear-gradient(90deg, rgba(170,218,197,1) 0%, rgba(73,119,186,1) 35%, rgba(73,119,186,1) 50%, rgba(241,200,210,1) 100%);
    padding: 2em;

    max-width: 900px;
    width: 100%;
    text-align: center;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);

    a {
      text-decoration: none;
      font-size: inherit;
      color: inherit;
    }

    h4,p {
      color: $color-white;
      margin: 0 auto;
      text-shadow: 0px 1px 1px rgba($color-black, .5);
    }
    h4 {
      font-size: 2em;
      font-weight: 700;
    }

    p {
      font-size: 1.4em;
      font-weight: 500;
      
      strong {
        font-weight: 600;
      }
    }
  }
}
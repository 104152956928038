.section {
  &-countdown {
    color: $color-white;
    // background: rgb(54,3,136);
    // background: linear-gradient(45deg, rgba(54,3,136,1) 0%, rgba(101,8,189,1) 25%, rgba(116,11,204,1) 50%, rgba(96,40,223,1) 75%, rgba(54,89,235,1) 100%);  
    background-color: rgba(0,0,0,.65);
    text-align: center;
    font-family: 'WPP-Font';
    font-weight: 700;
    // padding: 5rem 0 7rem;
    padding-bottom: 96px;
    h2 {
      font-size: 32px;
      margin-bottom: 19.2px;
      max-width: 600px;
      margin: 0 auto;
    }
  }
}

.countdown {
  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px 0 20px;
  }

  &-unit {
    padding: 0 32px;
    span {
      display: block;
    }
  }

  &-value {
    font-size: 96px;
    line-height: .8em;
    letter-spacing: -0.02em;
  }

  &-label {
    font-size: 19.2px;
    line-height: 1em;
  }
}

@media screen and (max-width: $screen-tab) {
    .section-countdown {
      padding-bottom: 60px;
      h2 {
        font-size: 26px;
      }
    }

    .countdown {
      &-value {
        font-size: 70px;
      }

      &-label {
        font-size: 16px;
      }
    }
}

@media screen and (max-width: $screen-mob) {
  .section-countdown {
    padding-bottom: 60px;
    h2 {
      font-size: 26px;
    }
  }

  .countdown {

    &-wrapper {
      flex-wrap: wrap;
    }

    &-unit {
      padding: 0 10px;
      margin-bottom: 20px;

      &:last-of-type {
        display: none !important;
      }
    }

    &-value {
      font-size: 70px;
    }

    &-label {
      font-size: 16px;
    }
  }
}
.section-register {
  background-color: $color-white;
  padding: 80px 0;
  .container {
    h2 {
      font-size: 42px;
      text-align: center;
    }

    p {
      font-size: 28px;
      text-align: center;
    }
  }
}

@media screen and (max-width: $screen-tab) {
  .section-register {
    padding: 40px 0;

    .container {  
      p {
        font-size: 22px;
      }
    }
  }
}
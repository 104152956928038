.containerapp {
  width: 420px;
  margin: 0 auto;
  font-size: 18px;
  padding: 0 1.778em 1.111em;
  position: relative;

  // min-height: 100svh;
  min-height: 100svh;
  height: 100%;

  @media screen and (max-width: 768px) {
    font-size: calc( 18 * 100vw / 420 );
    width: 100%;
  }

  background: url(../assets/images/app-bottom.png) no-repeat left bottom, linear-gradient(193.63deg, #F8C6D2 0.38%, #8CCDC4 20.39%, #0095D7 84.19%);
  background-size: 100%;

  &.stand {
    background: #fff;
    padding: 0;
  }

  &.popup-active {
    .containerapp-inner {
      filter: blur(2px);
    }

    .popup {
      display: flex;
    }
  }

  .h1 {
    font-size: 2em;
    line-height: 1.194em;
    color: $color-white;
    margin-bottom: .5em;
  }

  .logo {
    padding-top: 1.111em;
    text-align: center;
    margin-bottom: 3.889em;
    display: block;

    img {
      width: 9.278em;
      margin: 0 auto;
    }
  }

  .steps {
    margin-bottom: 2em;
    width: 100%;
  }

  form {
    margin-top: 2.222em;

    &.select {
      margin-top: 0;
    }
  }

  .popup {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    align-items: center;
    justify-content: center;
    filter: blur(0);

    &-inner {
      width: calc(100% - 2em);
      background-color: #F8C6D2;
      text-align: center;
      border-radius: 10px;

      > p {
        font-size: 1.8em;

        strong {
          font-weight: 600;
        }
      }

      padding: 1.111em 2.222em;

      .scanned {
        color: #191D63;
        span {
          font-size: 4.222em;
          &:first-of-type {
            font-weight: 900;
          }
        }
      }

      p {
        color: #191D63;
      }

      button {
        max-width: 14em;
        width: 100%;
        margin-top: 1em;
      }

      .secondary {
        border-color: #E121A2;
        color: #E121A2;
      }
    }
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    background: #fff !important;
    padding: 1.111em 1.778em;
  }

  .hint.stands-bg--brand,
  fieldset.stands-bg--brand {
    color: #D24E26;
  }

  .form-checkbox {
    input {
      display: none;
    }

    label {
      padding: .2em;
      display: block;
      text-transform: uppercase;
    }

    input:checked + label {
      background: #D9D9D9;
    }
  }

  .form-input {
    color: $color-white;
    margin-bottom: 2em;
    label {
      display: block;
      font-size: 0.667em;
      line-height: 0em;
      margin: 0;
    }

    input {
      display: block;
      width: 100%;
      background: none;
      border: none;
      border-bottom: 1px solid #6B15D5;
      font-size: 0.889em;
      padding: 0.5em 0;
      color: $color-white;

      &:focus {
        outline: none;
      }
    }

    input::placeholder {
      color: $color-white;
    }
  }

  .buttons {
    margin: 0;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.778em 1.111em;

  }

  .bg-none {
    background: none;
    background-color: transparent;
    padding: 0 0 1.111em;
  }

  button,
  a.button {
    font-size: 0.889em;
    border: none;
    cursor: pointer;
    padding: 1em 2.6em;
    border-radius: 4px;
    text-transform: uppercase;
    background: none;
    font-weight: 600;
    text-decoration: none;
  }

  button.primary {
    background-color: #E121A2;
    color: $color-white;
  }

  button.primary:disabled {
    background-color: $color-gray;
    cursor: not-allowed;
  }

  button.secondary,
  a.secondary {
    border: 1px solid #6B15D5;
    color: #6B15D5;
  }

  .intro {
    padding: 1em;
    p, .h1 {
      color: inherit;
    }
  }

  p {
    color: $color-white;
  }

  .text {
    font-size: 1em;
    color: $color-white;
    line-height: 1.222em;
  }

  ol.text {
    padding-left: 25px;

    li {
      font-size: 1em;
      color: #f2f2f2;
      line-height: 1.222em;
      margin-bottom: .5em;
    }
  }

  .checkbox-confirm {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input {
      display: none;
    }

    input:checked + label {
      &:after {
        background-image: url(../assets/images/icons-sprite.svg);
        background-position: -55px -145px;
        background-size: 226px 212px;
      }
    }

    a {
      color: #f2f2f2;
      font-size: .8em;
      margin-left: .35em;
    }
  
    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      

      span {
        color: #f2f2f2;
        font-size: .8em;
        padding-left: 2em;
        a {
          color: inherit;
        }
      }

      &:after,
      &:before {
        width: 1em;
        height: 1em;
        content: '';
        display: inline-block;
        border: 1px solid #f2f2f2;
        border-radius: 5px;
        position: absolute;
      }
    }
  }

  .small {
    font-size: 0.778em;
    line-height: 1.214em;
    margin: 2em 0;
  }

  .hint {
    font-size: 0.778em;
    line-height: 1.214em;
    background: #fff !important;
    margin: 0;
    padding: 0 2.286em 1.429em;
    text-align: center;
  }

  .bigger {
    font-size: 1.222em;
    line-height: 1.222em;
  }

  .bigger + .bigger {
    margin-top: 4em;
  }

  .prizes {
    list-style: none;
    padding: 0;
    margin: 3em 0 0;
    + p {
      margin-bottom: 40px;
    }
    li {
      font-size: 1em;
      line-height: 1.222em;
      display: grid;
      grid-template-columns: 3.056em 1fr;
      gap: .8em;
      align-items: center;
      margin-bottom: 2.8em;


      .badge {
        width: 3.056em;
        height: 3.056em;
        border-radius: 50%;
        flex-grow: 1;
        color: #191D63;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
          font-size: 0.6em;
          line-height: 0.6em;
          &:first-of-type {
            font-weight: 900;
            font-size: 1.278em;
            line-height: .8em;
            margin-bottom: .2em;
          }

          sup {
            font-size: .7em;
            font-weight: 300;
            position: relative;
            top: 1px;
            left: 1px;
          }
        }
      }

      &:nth-of-type(1) {
        .badge {
          background-color: #BCB19A;
        }
      }
      &:nth-of-type(2) {
        .badge {
          background-color: #D9D9D9;
        }
      }
      &:nth-of-type(3) {
        .badge {
          background-color: #B09C85;
        }
      }
    }
  }
}
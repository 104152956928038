header {
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
  background-color: $color-white;
  font-weight: 600;

  .nav-main.nav-open {
    .nav-icon {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 18px;
          left: 15px;
        }

        &:nth-child(2) {
          width: 0%;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 39px;
          left: 15px;
        }
      }
    }

    .nav-list {
      transform: translateX(0);
    }
  }
}


.nav {
  &-icon {
    display: none;
    background-color: transparent;
    border: none;

    width: 30px;
    height: 26px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    padding: 30px;

    @media screen and (max-width: $screen-tab) {
      display: inline-block;
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 30px;
      background: $color-blue;
      border-radius: 9px;
      opacity: 1;
      left: 15px;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 17px;
        transform-origin: left center;
      }

      &:nth-child(2) {
        top: 28px;
        transform-origin: left center;
      }

      &:nth-child(3) {
        top: 39px;
        transform-origin: left center;
      }
      
    }
  }

  &-main {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    list-style: none;

    @media screen and (max-width: $screen-tab) {
      position: absolute;
      transform: translateX(-150%);
      transition: transform .3s ease-in;
      top: 60px;
      display: block;
      left: 0;
      width: 100%;
      padding: 1rem 1.25rem 2rem;
      background-color: $color-white;
      z-index: 10000;
      text-align: center;
    }
  }

  &-item {
    padding-right: 2rem;

    @media screen and (max-width: $screen-tab) {
      padding: 0;
      border-bottom: 1px solid rgba($color-blue, .3);
      
      &:nth-last-child(2),
      &:last-of-type{
        border-bottom: none;
      }
    }

    a {
      font-size: 0.875rem;
      text-decoration: none;
      padding: 1.25rem 0 1.5rem;
      color: $color-blue;
      border-top: 5px solid transparent;

      transition: border-color .3s ease-in;

      @media screen and (max-width: $screen-tab) {
        border-top: none;
      }
    }

    &:hover {
      a {
        border-top: 5px solid #000050;

        @media screen and (max-width: $screen-tab) {
          border-top: none;
        }
      }
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  &-logo {
    a {
      display: inline;
    }
    img {
      height: 35px;
      width: auto;
    }
  }
}